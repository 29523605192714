import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: 'sites',
        loadChildren: () => import('src/app/admin/sites/sites.module').then(m => m.SitesModule)
    },
    {
        path: 'buildings',
        loadChildren: () => import('src/app/admin/buildings/buildings.module').then(m => m.BuildingsModule)
    },
    {
        path: 'contacts',
        loadChildren: () => import('src/app/admin/contacts/contacts.module').then(m => m.ContactsModule)
    },
    {
        path: 'customers',
        loadChildren: () => import('src/app/admin/customers/customers.module').then(m => m.CustomersModule)
    },
    {
        path: 'drawings',
        loadChildren: () => import('src/app/admin/drawings/drawings.module').then(m => m.DrawingsModule)
    },
    {
        path: 'floors',
        loadChildren: () => import('src/app/admin/floors/floors.module').then(m => m.FloorsModule)
    },
    {
        path: 'manufacturers',
        loadChildren: () => import('src/app/admin/manufacturers/manufacturers.module').then(m => m.ManufacturersModule)},
    {
        path: 'pin-histories',
        loadChildren: () => import('src/app/admin/pin-histories/pin-histories.module').then(m => m.PinHistoriesModule)},
    {
        path: 'pins',
        loadChildren: () => import('src/app/admin/pins/pins.module').then(m => m.PinsModule)
    },
    {
        path: 'products',
        loadChildren: () => import('src/app/admin/products/products.module').then(m => m.ProductsModule)
    },
    {
        path: 'defects',
        loadChildren: () => import('src/app/admin/defects/defects.module').then(m => m.DefectsModule)
    },
    {
        path: 'users',
        loadChildren: () => import('src/app/admin/users/users.module').then(m => m.UsersModule)
    },
    {
        path: 'reports',
        loadChildren: () => import('src/app/admin/reports/reports.module').then(m => m.ReportsModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('src/app/admin/settings/settings.module').then(m => m.SettingsModule)
    },
    // {
    //     path: '',
    //     pathMatch: 'full',
    //     redirectTo: 'sites'
    //     // component: DashboardComponent,
    // },
];

//TODO: Commented out the above catch all route because settings page is inaccessible otherwise, needs looking at

@NgModule({
    providers: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule {
}
