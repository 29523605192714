import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog}                                   from '@angular/material/dialog';
import {MatPaginator, PageEvent}                     from '@angular/material/paginator';
import {MatTableDataSource}                          from '@angular/material/table';
import {Router}                                      from '@angular/router';
import {ProductsFormComponent}                       from '@src/app/admin/products/components/form/products-form.component';
import {ProductService}                              from '@src/app/admin/products/services/product.service';
import {GlobalService}                               from '@src/app/core/services/global.service';
import {Breadcrumb}                                  from '@src/app/shared/models/breadcrumb.model';
import {Button}                                      from '@src/app/shared/models/button.model';

@Component({
    selector: 'app-products',
    templateUrl: './products-index.component.html',

})
export class ProductsIndexComponent implements OnInit, AfterViewInit
{
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    //'item_type_id' , 'width', 'height'
    public displayedColumns: string[] = ['name', 'status_id', 'direct_employee_cost', 'sub_contractor_cost', 'material_cost', 'sell_price', 'manufacturer_id', 'actions'];
    public products: MatTableDataSource<any> = new MatTableDataSource();
    public totalRows: number = 0;
    public pageSize: number = 50;
    public currentPage: number = 0;
    public pageSizeOptions: number[] = [5, 10, 25, 100];
    public breadcrumbs: Breadcrumb[] = [
        {
            title: 'Home',
            url: '/app/',
            active: false
        },
        {
            title: 'Products',
            active: true
        },
    ];
    public buttons: Button[] = [
        {
            type: 'button',
            material: 'flat',
            color: 'primary',
            title: 'Create Product',
            permission: 'add_products',
            callback: () => this.openDialog(),
        }
    ];

    public constructor(
        private globalService: GlobalService,
        private productService: ProductService,
        public router: Router,
        public dialog: MatDialog,
    )
    {
    }

    public ngOnInit(): void
    {
        this.globalService.pageTitle = 'Products';
        this.loadProducts();
    }

    public ngAfterViewInit()
    {
        this.products.paginator = this.paginator;
    }

    public loadProducts()
    {
        this.productService.GetProducts(this.currentPage + 1, this.pageSize).subscribe(
            response => {
                this.products = response.data;
                this.paginator.pageIndex = this.currentPage;
                this.paginator.length = response.meta.total;
            },
            error => this.productService.ErrorHandler(error)
        );
    }

    public pageChanged(event: PageEvent)
    {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;

        this.loadProducts();
    }

    public openDialog(action: string = 'create', product = null)
    {
        const productsDialog = this.dialog.open(ProductsFormComponent, {
            width: '50%',
            disableClose: true,
            data: {
                action: action,
                product: product
            },
        });

        productsDialog.afterClosed().subscribe(result => {
            if (result) {
                this.loadProducts();
            }
        });
    }

    public onDelete(id)
    {
        this.productService.DeleteProduct(id).subscribe(() => {
            this.loadProducts();
        }, response => this.productService.ErrorHandler(response));
    }
}
