import {Injectable} from '@angular/core';
import {environment} from '@src/environments/environment';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseService {
    protected apiUrl: string;

    protected constructor() {
        this.apiUrl = environment.apiUrl;
    }

    public handleError<T>(result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }

}
