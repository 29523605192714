import {Injectable} from '@angular/core';
import {environment} from '@src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SiteService} from '@src/app/admin/sites/services/site.service';
import {BuildingService} from '@src/app/admin/buildings/services/building.service';
import {FloorService} from '@src/app/admin/floors/services/floor.service';
import {DrawingService} from '@src/app/admin/drawings/services/drawing.service';
import {PinService} from '@src/app/admin/pins/services/pin.service';

@Injectable({
    providedIn: 'root'
})
export class PinHistoryService {
    baseUrl: string;
    pinHistoryId: number;

    constructor(
        private http: HttpClient,
        private siteService: SiteService,
        private buildingService: BuildingService,
        private floorService: FloorService,
        private drawingService: DrawingService,
        private pinService: PinService,
    ) {
        this.baseUrl = environment.apiUrl;
    }

    CreatePinHistory(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/pin-histories`, data).pipe();
    }

    EditPinHistory(data, id): Observable<any> {
        return this.http.post(`${this.baseUrl}/pin-histories/${id}?_method=PUT`, data).pipe();
    }

    SwapProductInPinHistory(id, data): Observable<any> {
        return this.http.put(`${this.baseUrl}/pin-histories/${id}/swap-product`, data).pipe();
    }

    GetPinHistoryData(id) {
        this.siteService.GetSitesData();
        return this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId())
            .buildings.find(c => c.id === this.buildingService.GetBuildingId())
            .floors.find(c => c.id === this.floorService.GetFloorId())
            .drawings.find(c => c.id === this.drawingService.GetDrawingId())
            .pins.find(c => c.id === this.pinService.GetPinId())
            .histories.find(c => c.id === this.SetPinHistoryId(id));
    }

    modifyPinHistoryData(type, data) {
        console.log(type, data);
        const siteIndex = this.siteService.siteData.findIndex(c => c.id === this.siteService.GetSiteId());
        const buildingIndex = this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId()).buildings.findIndex(c => c.id === this.buildingService.GetBuildingId());
        const floorIndex = this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId()).buildings.find(c => c.id === this.buildingService.GetBuildingId()).floors.findIndex(c => c.id === this.floorService.GetFloorId());
        const drawingIndex = this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId()).buildings.find(c => c.id === this.buildingService.GetBuildingId()).floors.find(c => c.id === this.floorService.GetFloorId()).drawings.findIndex(c => c.id === this.drawingService.GetDrawingId());
        const pinIndex = this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId()).buildings.find(c => c.id === this.buildingService.GetBuildingId()).floors.find(c => c.id === this.floorService.GetFloorId()).drawings.find(c => c.id === this.drawingService.GetDrawingId()).pins.findIndex(c => c.id === this.pinService.GetPinId());

        if (type === 'edit') {
            const pinHistoryIndex = this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId()).buildings.find(c => c.id === this.buildingService.GetBuildingId()).floors.find(c => c.id === this.floorService.GetFloorId()).drawings.find(c => c.id === this.drawingService.GetDrawingId()).pins.findIndex(c => c.id === data.id);
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingIndex].pins[pinIndex].histories[pinHistoryIndex].rating_type = data.rating_type;
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingIndex].pins[pinIndex].histories[pinHistoryIndex].status_id = data.status_id;
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingIndex].pins[pinIndex].histories[pinHistoryIndex].notes = data.notes;
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingIndex].pins[pinIndex].histories[pinHistoryIndex].flame_resistance = data.flame_resistance;
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingIndex].pins[pinIndex].histories[pinHistoryIndex].arc_rating = data.arc_rating;
        } else {
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingIndex].pins[pinIndex].histories.push(data);
        }

        this.siteService.SetSitesData(this.siteService.siteData);

        return this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingIndex].pins[pinIndex].histories;
    }

    SetPinHistoryId(id) {
        localStorage.setItem('pinHistoryId', JSON.stringify(id));
        this.pinHistoryId = id;
        return this.pinHistoryId;
    }

    GetPinHistoryId() {
        const pinHistoryId = localStorage.getItem('pinHistoryId');

        if (pinHistoryId) {
            try {
                this.pinHistoryId = JSON.parse(pinHistoryId);
            } catch (e) {
                localStorage.removeItem('pinHistoryId');
            }

            return this.pinHistoryId;
        }
    }

    ErrorHandler(response) {
        console.log(response);
    }
}
