import {Injectable} from '@angular/core';
import {BaseService} from '../../shared/services/base.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService extends BaseService {
    constructor() {
        super();
    }

    public get roles(): String[] {
        return JSON.parse(localStorage.getItem('roles')) ?? null;
    }

    public set roles(roles: String[]) {
        localStorage.setItem('roles', JSON.stringify(roles));
    }

    public get permissions(): String[] {
        return JSON.parse(localStorage.getItem('permissions')) ?? null;
    }

    public set permissions(permissions: String[]) {
        localStorage.setItem('permissions', JSON.stringify(permissions));
    }

    public can(permission: string) {
        return true;
        return !!this.permissions.find(p => p === permission) || this.hasRole('super-admin');
    }

    public cant(permission: string) {
        return !this.permissions.find(p => p === permission);
    }

    public hasRole(role: string) {
        return this.roles.find(p => p === role);
    }
}
