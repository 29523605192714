import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    public token: string;
    public email: string;
    public restPasswordForm: FormGroup;
    public error: any;
    public submitted = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private authService: AuthService,
        private snackBar: MatSnackBar,
    ) {
        if (this.authService.authenticated) {
            this.router.navigate(['/app']).then(r => r);
        }

        this.token = this.activatedRoute.snapshot.paramMap.get('token');
        this.activatedRoute.queryParams.subscribe(params => {
           this.email = params.email;
        });
    }

    get f() {
        return this.restPasswordForm.controls;
    }

    public ngOnInit(): void {
        this.restPasswordForm = this.fb.group({
            token: [this.token, Validators.required],
            email: [{value: this.email ?? null, disabled: true}, [Validators.required, Validators.email]],
            password: [null, Validators.required],
            password_confirmation: [null, Validators.required],
        });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (this.restPasswordForm.invalid) {
            return;
        }

        this.restPasswordForm.disable();

        this.authService.resetPassword(this.restPasswordForm.value).subscribe(
            () => {
                this.snackBar.open('Password successfully reset.', 'Ok');
                this.router.navigateByUrl('/login').then(r => r);
            },
            (response: HttpErrorResponse) => {
                this.restPasswordForm.enable();
                this.snackBar.open(response?.error?.message, 'Ok');
            }
        );
    }
}
