import {Component} from '@angular/core';
import { version } from 'package.json';

@Component({
    selector: 'app-admin-footer',
    templateUrl: './admin-footer.component.html',
    styleUrls: ['./admin-footer.component.scss']
})
export class AdminFooterComponent {
    public currentYear = new Date().getFullYear();
    public version: string;

    public constructor() {
        this.version = version;
    }
}
