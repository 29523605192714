import {Injectable} from '@angular/core';
import {environment} from '@src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.apiUrl;
    }

    GetProducts(pageNumber: number, pageSize: number = 50): Observable<any> {
        const url = `${this.baseUrl}/products?page[number]=${pageNumber}&page[size]=${pageSize}`;

        return this.http.get(url).pipe();
    }

    GetProductsByManufacturer(): Observable<any> {
        const url = `${this.baseUrl}/models/Product/Products?value=name`;
        return this.http.get(url).pipe();
    }

    GetProduct(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/products/${id()}`).pipe();
    }

    CreateProduct(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/products`, data).pipe();
    }

    EditProduct(data, id): Observable<any> {
        return this.http.put(`${this.baseUrl}/products/${id}`, data).pipe();
    }

    DeleteProduct(id): Observable<any> {
        return this.http.delete(`${this.baseUrl}/products/${id}`).pipe();
    }

    GetProductTypes(): Observable<any> {
        return this.http.get(`${this.baseUrl}/products/types`).pipe();
    }

    ErrorHandler(response) {
        console.log(response);
    }
}
