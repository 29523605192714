<mat-toolbar color="primary">
  <mat-toolbar-row>
      <span>{{title}}</span>

      <span class="flex-spacer"></span>

      <button mat-icon-button class="float-end" [matMenuTriggerFor]="userMenu">
          <mat-icon>account_circle</mat-icon>
      </button>

      <mat-menu #userMenu="matMenu">
          <button mat-menu-item (click)="logout()">
              <mat-icon>logout</mat-icon>
              <span>Sign Out</span>
          </button>

          <mat-divider></mat-divider>

          <button mat-menu-item [disabled]="true">
              <mat-icon>person</mat-icon>
              <span>{{ userService.user.name }}</span>
          </button>
      </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<mat-card class="mat-card mat-focus-indicator">
	<mat-card-title>
		<h2>Company Settings - {{organisationName}}</h2>
	</mat-card-title>

	<mat-card-content *ngIf="organisationForm">
		<form [formGroup]="organisationForm">
			<div class="row">
				<div class="col-12">
					<p>Welcome {{userService.user.name}},</p>
					<p>Thank you for signing up to fire survey pro, we just need to collect some address details before you can continue. Please enter the details below:</p>
				</div>
			</div>

			<div class="row">
				<div class="col-12 col-md-6">
					<form formGroupName="trading_address">
						<div class="row">
							<h3 class="col-12">Trading Address</h3>
						</div>

						<mat-form-field>
							<mat-label>Address 1</mat-label>
							<input matInput type="text" formControlName="address_line_1" required>
						</mat-form-field>
						<div class="text-danger"
							*ngIf="organisationForm.get('trading_address.address_line_1').errors && (organisationForm.get('trading_address.address_line_1').dirty || organisationForm.get('trading_address.address_line_1').touched) ">
							<div *ngIf="organisationForm.get('trading_address.address_line_1').errors.required" padding>
								*Please enter Address 1.
							</div>
						</div>

						<mat-form-field>
							<mat-label>Address 2</mat-label>
							<input matInput type="text" formControlName="address_line_2">
						</mat-form-field>
						<!-- <div class="text-danger"
							*ngIf="organisationForm.get('trading_address.address_line_2').errors && (organisationForm.get('trading_address.address_line_2').dirty || organisationForm.get('trading_address.address_line_2').touched) ">
							<div *ngIf="organisationForm.get('trading_address.address_line_2').errors.required" padding>
								*Please enter Address 2.
							</div>
						</div> -->

						<mat-form-field>
							<mat-label>Address 3</mat-label>
							<input matInput type="text" formControlName="address_line_3">
						</mat-form-field>
						<!-- <div class="text-danger"
							*ngIf="organisationForm.get('trading_address.address_line_3').errors && (organisationForm.get('trading_address.address_line_3').dirty || organisationForm.get('trading_address.address_line_3').touched) ">
							<div *ngIf="organisationForm.get('trading_address.address_line_3').errors.required" padding>
								*Please enter Address 3.
							</div>
						</div> -->

						<mat-form-field>
							<mat-label>locality / City</mat-label>
							<input matInput type="text" formControlName="locality">
						</mat-form-field>
						<!-- <div class="text-danger"
							*ngIf="organisationForm.get('trading_address.locality').errors && (organisationForm.get('trading_address.locality').dirty || organisationForm.get('trading_address.locality').touched) ">
							<div *ngIf="organisationForm.get('trading_address.locality').errors.required" padding>
								*Please enter locality / city.
							</div>
						</div> -->

						<mat-form-field>
							<mat-label>County</mat-label>
							<input matInput type="text" formControlName="county">
						</mat-form-field>
						<!-- <div class="text-danger"
							*ngIf="organisationForm.get('trading_address.county').errors && (organisationForm.get('trading_address.county').dirty || organisationForm.get('trading_address.county').touched) ">
							<div *ngIf="organisationForm.get('trading_address.county').errors.required" padding>
								*Please enter County.
							</div>
						</div> -->

						<mat-form-field>
							<mat-label>Country</mat-label>
							<input matInput type="text" formControlName="country">
						</mat-form-field>
						<!-- <div class="text-danger"
							*ngIf="organisationForm.get('trading_address.country').errors && (organisationForm.get('trading_address.country').dirty || organisationForm.get('trading_address.country').touched) ">
							<div *ngIf="organisationForm.get('trading_address.country').errors.required" padding>
								*Please enter Country.
							</div>
						</div> -->

						<mat-form-field>
							<mat-label>Postcode</mat-label>
							<input matInput type="text" formControlName="postcode"
								(input)="forceUppercaseConditionally('trading_address', 'postcode', $event);"
								required />
						</mat-form-field>
						<div class="text-danger"
							*ngIf="organisationForm.get('trading_address.postcode').errors && (organisationForm.get('trading_address.postcode').dirty || organisationForm.get('trading_address.postcode').touched) ">
							<div *ngIf="organisationForm.get('trading_address.postcode').errors.required" padding>
								*Please enter postcode.
							</div>
						</div>
					</form>
				</div>

				<div class="col-12 col-md-6">
					<form formGroupName="billing_address">
						<div class="row">
							<h3 class="col-12 col-md-8">Billing Address</h3>
							<mat-checkbox class="col-12 col-md-4 text-right" color="primary"
							(change)="sameAsTradingAddress($event)">Same as trading
							address?</mat-checkbox>
						</div>

						<mat-form-field>
							<mat-label>Address 1</mat-label>
							<input matInput type="text" formControlName="address_line_1" required>
						</mat-form-field>
						<div class="text-danger"
							*ngIf="organisationForm.get('billing_address.address_line_1').errors && (organisationForm.get('billing_address.address_line_1').dirty || organisationForm.get('billing_address.address_line_1').touched) ">
							<div *ngIf="organisationForm.get('billing_address.address_line_1').errors.required" padding>
								*Please enter Address 1.
							</div>
						</div>

						<mat-form-field>
							<mat-label>Address 2</mat-label>
							<input matInput type="text" formControlName="address_line_2">
						</mat-form-field>
						<!-- <div class="text-danger"
							*ngIf="organisationForm.get('billing_address.address_line_2').errors && (organisationForm.get('billing_address.address_line_2').dirty || organisationForm.get('billing_address.address_line_2').touched) ">
							<div *ngIf="organisationForm.get('billing_address.address_line_2').errors.required" padding>
								*Please enter Address 2.
							</div>
						</div> -->

						<mat-form-field>
							<mat-label>Address 3</mat-label>
							<input matInput type="text" formControlName="address_line_3">
						</mat-form-field>
						<!-- <div class="text-danger"
							*ngIf="organisationForm.get('billing_address.address_line_3').errors && (organisationForm.get('billing_address.address_line_3').dirty || organisationForm.get('billing_address.address_line_3').touched) ">
							<div *ngIf="organisationForm.get('billing_address.address_line_3').errors.required" padding>
								*Please enter Address 3.
							</div>
						</div> -->

						<mat-form-field>
							<mat-label>locality / City</mat-label>
							<input matInput type="text" formControlName="locality">
						</mat-form-field>
						<!-- <div class="text-danger"
							*ngIf="organisationForm.get('billing_address.locality').errors && (organisationForm.get('billing_address.locality').dirty || organisationForm.get('billing_address.locality').touched) ">
							<div *ngIf="organisationForm.get('billing_address.locality').errors.required" padding>
								*Please enter locality / city.
							</div>
						</div> -->

						<mat-form-field>
							<mat-label>County</mat-label>
							<input matInput type="text" formControlName="county">
						</mat-form-field>
						<!-- <div class="text-danger"
							*ngIf="organisationForm.get('billing_address.county').errors && (organisationForm.get('billing_address.county').dirty || organisationForm.get('billing_address.county').touched) ">
							<div *ngIf="organisationForm.get('billing_address.county').errors.required" padding>
								*Please enter County.
							</div>
						</div> -->

						<mat-form-field>
							<mat-label>Country</mat-label>
							<input matInput type="text" formControlName="country">
						</mat-form-field>
						<!-- <div class="text-danger"
							*ngIf="organisationForm.get('billing_address.country').errors && (organisationForm.get('billing_address.country').dirty || organisationForm.get('billing_address.country').touched) ">
							<div *ngIf="organisationForm.get('billing_address.country').errors.required" padding>
								*Please enter Country.
							</div>
						</div> -->

						<mat-form-field>
							<mat-label>Postcode</mat-label>
							<input matInput type="text" formControlName="postcode"
								(input)="forceUppercaseConditionally('billing_address', 'postcode', $event);"
								required />
						</mat-form-field>
						<div class="text-danger"
							*ngIf="organisationForm.get('billing_address.postcode').errors && (organisationForm.get('billing_address.postcode').dirty || organisationForm.get('billing_address.postcode').touched) ">
							<div *ngIf="organisationForm.get('billing_address.postcode').errors.required" padding>
								*Please enter postcode.
							</div>
						</div>
					</form>
				</div>
			</div>
		</form>

		<div class="row">
			<button mat-raised-button color="primary" (click)="submitForm()">Submit</button>
		</div>
	</mat-card-content>
</mat-card>