<mat-card class="p-4 pt-0 align-self-center">
	<mat-card-header>
		<mat-card-title class="mt-4">Sign-up</mat-card-title>
		<mat-card-subtitle>Please enter your details to complete signing up.</mat-card-subtitle>

		<img src="../../../../assets/images/logo/dwc.png" alt="Fire Survey Pro" class="login-logo" />
	</mat-card-header>

	<form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
		<mat-card-content>
			<div class="row">
				<mat-form-field class="col-12">
					<input type="text" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" matInput
						placeholder="Company Name" formControlName="company_name" autocomplete="organization">
					<div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
						<div *ngIf="f.company_name.errors.required">
							Company Name is required.
						</div>
					</div>
				</mat-form-field>
			</div>

			<div class="row">
				<mat-form-field class="col-12 col-md-6">
					<input type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" matInput
						placeholder="Full Name" formControlName="name" autocomplete="name">
					<div *ngIf="submitted && f.name.errors" class="invalid-feedback">
						<div *ngIf="f.name.errors.required">
							Full Name is required.
						</div>
					</div>
				</mat-form-field>

				<mat-form-field class="col-12 col-md-6">
					<input type="text" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" matInput
						placeholder="Email" formControlName="email" autocomplete="email">
					<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
						<div *ngIf="f.email.errors.required">
							Email is required.
						</div>

						<div *ngIf="f.email.errors.email">
							Please enter a valid email address.
						</div>
					</div>
				</mat-form-field>

				<mat-form-field class="col-12 col-md-6">
					<input type="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" matInput
						placeholder="Password" formControlName="password" autocomplete="current-password">
					<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
						<div *ngIf="f.password.errors.required">
							Password is required.
						</div>
					</div>
				</mat-form-field>

				<mat-form-field class="col-12 col-md-6">
					<input type="password" [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" matInput
						placeholder="Confirm Password" formControlName="password_confirmation"
						autocomplete="current-password">
					<div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
						<div *ngIf="f.password_confirmation.errors.required">
							Confirm Password is required.
						</div>
						<!-- <div *ngIf="f.confirmPassword.errors['confirmedValidator']">
						Passwords do not match
					</div> -->
					</div>
				</mat-form-field>

				<!-- <mat-form-field class="col-12 col-md-6">
					<input type="text" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" matInput
						placeholder="Mobile Number" formControlName="mobile" autocomplete="tel">
					<div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
						<div *ngIf="f.mobile.errors.required">
							Mobile Number is required.
						</div>
					</div>
				</mat-form-field>

				<mat-form-field class="col-12 col-md-6">
					<input type="text" [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }" matInput
						placeholder="Telephone Number" formControlName="telephone" autocomplete="tel">
					<div *ngIf="submitted && f.telephone.errors" class="invalid-feedback">
						<div *ngIf="f.telephone.errors.required">
							Telephone Number is required.
						</div>
					</div>
				</mat-form-field> -->
			</div>

			<div class="col-12 mt-3">
				<div *ngIf="error" class="alert alert-danger mt-1">{{error}}</div>
			</div>
		</mat-card-content>

		<mat-card-actions class="m-0">
			<button type="submit" mat-flat-button color="primary">Submit</button>
			<a mat-button color="primary" routerLink="/login">Return to login</a>
		</mat-card-actions>
	</form>
</mat-card>