import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import * as Leaflet from 'leaflet';
import { UserPreferencesService } from '../../services/user-preferences.service';

@Component({
	selector: 'app-user-prefereces',
	templateUrl: './user-prefereces.component.html',
	styleUrls: ['./user-prefereces.component.css']
})
export class UserPreferecesComponent implements OnInit, AfterViewInit {

	public userPreferecesForm: FormGroup;
	private map;
    private latestMarker;

	constructor(
		public UserPreferecesModal: MatDialogRef<UserPreferecesComponent>,
		public fb: FormBuilder,
		private UserPreferncesService: UserPreferencesService
	) {
		this.userPreferecesForm = this.fb.group({
			pin_dimensions_x: [50],
			pin_dimensions_y: [90]
		});
	}

	ngOnInit() {
		
	}

	ngAfterViewInit(): void {
		this.initMap();
	}

	onSubmit(){
		localStorage.setItem('userPreferences', JSON.stringify(this.userPreferecesForm.value));
		this.UserPreferecesModal.close();
	}

	public initMap() {
        this.map = Leaflet.map('mapPref', {
            crs: Leaflet.CRS.Simple,
            center: [3309, 2339],
            attributionControl: false,
            zoomSnap: 0,
            minZoom: -10,
            maxBoundsViscosity: 1.0,
            scrollWheelZoom: false
        });

        const bounds = new Leaflet.LatLngBounds([0, 0], [2339, 3309]);

        Leaflet.imageOverlay("assets/images/defaultMap.jpg", bounds).addTo(this.map);

        this.latestMarker = Leaflet.marker([Number(2339 / 2), Number(3309 / 2)], {
            draggable: false,
            icon: Leaflet.icon({
                iconUrl: "assets/images/RedPin.png",
                iconSize: [50, 90],
                iconAnchor: [25, 90],
            })
        }).addTo(this.map);

        this.map.setView(
            [(2339 / 2), (3309 / 2)],
            this.map.getBoundsZoom(bounds, true)
        );
    }

    onTabChanged(event) {
        if (event.index == 1 && this.map == null) {
            this.initMap();
        }
    }

    formatLabel(value: number) {
        return value + '%';
    }

    calculatePinSize(event) {
        let multiplier = event.value / 100;
        let x = 100 * multiplier;
        let y = 180 * multiplier;

        this.map.removeLayer(this.latestMarker);
        this.latestMarker = Leaflet.marker([Number(2339 / 2), Number(3309 / 2)], {
            draggable: false,
            icon: Leaflet.icon({
                iconUrl: "assets/images/RedPin.png",
                iconSize: [x, y],
                iconAnchor: [x / 2, y],
            })
        }).addTo(this.map);

        this.userPreferecesForm.controls['pin_dimensions_x'].setValue(x);
        this.userPreferecesForm.controls['pin_dimensions_y'].setValue(y);
    }

}
