import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from '@src/app/core/components/login/login.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from '@src/app/core/components/reset-password/reset-password.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { EmailVerifiedComponent } from './components/email-verified/email-verified.component';
import { CreatePasswordComponent } from './components/create-password/create-password.component';

export const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'Login'
        },
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'reset-password/:token',
        component: ResetPasswordComponent
    },
    {
        path: 'sign-up',
        component: SignUpComponent
    },
    {
        path: 'create-password',
        component: CreatePasswordComponent
    },
    {
        path: 'email-verified',
        component: EmailVerifiedComponent
    }

    // { path: 'register', component: RegisterComponent },
    // { path: 'confirm-email', component: ConfirmEmailComponent },
    // { path: 'page-not-found', component: PageNotFoundComponent },
    // { path: 'setting', component: SendLinkComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CoreRoutingModule {
}
