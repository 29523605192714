import {Component, Input} from '@angular/core';
import {Breadcrumb}       from '@src/app/shared/models/breadcrumb.model';
import {Button}           from '@src/app/shared/models/button.model';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent
{
    @Input() public title: string;
    @Input() public breadcrumbs: Breadcrumb[];
    @Input() public buttons: Button[];

    public constructor()
    {
    }

    public callback(button: Button)
    {
        button.callback();
    }
}
