import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class UserPreferencesService {

	constructor() {

	}

	getUserPreferences(){
		var pref = JSON.parse(localStorage.getItem('userPreferences'));

		return pref ? pref : null;
	}

}
