import {CommonModule}           from '@angular/common';
import {NgModule}               from '@angular/core';
import {BrowserModule}          from '@angular/platform-browser';
import {ProductsFormComponent}  from '@src/app/admin/products/components/form/products-form.component';
import {ProductsIndexComponent} from '@src/app/admin/products/components/index/products-index.component';
import {ProductsRoutingModule}  from '@src/app/admin/products/products-routing.module';
import {AdminModule}            from '@src/app/layout/admin/admin.module';

@NgModule({
    imports: [
        ProductsRoutingModule,
        AdminModule,
        CommonModule,
    ],
    declarations: [
        ProductsIndexComponent,
        ProductsFormComponent,
    ],
    exports: [
        ProductsIndexComponent,
        ProductsFormComponent,
    ],
})
export class ProductsModule
{
}
