import {Component}               from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router}                  from '@angular/router';
import {UserPreferecesComponent} from '@src/app/admin/users/components/user-prefereces/user-prefereces.component';
import {UsersService}            from '@src/app/admin/users/services/users.service';
import {AuthService}             from '@src/app/core/services/auth.service';
import {GlobalService}           from '@src/app/core/services/global.service';
import {environment}             from '@src/environments/environment';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent
{
    public title: string = '';
    public pageTitle: string = '';
    public links: any = [
        // {name: 'Home', url: 'app', icon: 'home'},
        {name: 'Sites', url: 'app/sites', icon: 'room'},
        {name: 'Reports', url: 'app/reports', icon: 'bar_chart'},
        {name: 'Clients', url: 'app/customers', icon: 'business'},
        {name: 'Products', url: 'app/products', icon: 'category'},
        {name: 'Defects', url: 'app/defects', icon: 'warning'},
        {name: 'Manufacturers', url: 'app/manufacturers', icon: 'precision_manufacturing'},
        {name: 'Users', url: 'app/users', icon: 'people'},
        {name: 'Settings', url: 'app/settings', icon: 'settings'},
    ];

    public constructor(
        public dialog: MatDialog,
        public usersService: UsersService,
        public authService: AuthService,
        private router: Router,
        private globalService: GlobalService,
    )
    {
        this.title = environment.title;
        this.pageTitle = this.globalService.pageTitle;
    }

    public logout(): void
    {
        this.authService.logout();
        this.router.navigate(['/login']).then(r => r);
    }

    public openUserPreferences(): void
    {
        this.dialog.open(UserPreferecesComponent, {
            width: '50%',
            disableClose: true,
        });
    }

    // TODO: add sweet alert to confirm loss of any unsaved data when switching tenant
    // TODO: Create a tenant type
    public switchTenant(tenant): void
    {
        this.authService.tenant = tenant;
        this.router.navigate(['/app/sites']);
        window.location.reload();
    }

    public throwTestError(): void
    {
        throw new Error('Sentry Test Error');
    }
}
