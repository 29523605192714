import {EventEmitter, Injectable, Output} from '@angular/core';
import {environment} from '@src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SiteService} from '@src/app/admin/sites/services/site.service';
import {BuildingService} from '@src/app/admin/buildings/services/building.service';
import {FloorService} from '@src/app/admin/floors/services/floor.service';
import {DrawingService} from '@src/app/admin/drawings/services/drawing.service';

@Injectable({
    providedIn: 'root'
})
export class PinService {
    baseUrl: string;
    pinId: number;
    @Output() pinAddedEvent: EventEmitter<[]> = new EventEmitter<[]>();

    constructor(
        private http: HttpClient,
        private siteService: SiteService,
        private buildingService: BuildingService,
        private floorService: FloorService,
        private drawingService: DrawingService,
    ) {
        this.baseUrl = environment.apiUrl;
    }

    CreatePin(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/pins`, data).pipe();
    }

    EditPin(data, id): Observable<any> {
        return this.http.put(`${this.baseUrl}/pins/${id}`, data).pipe();
    }

    GetPinData(id) {
        this.siteService.GetSitesData();
        return this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId())
            .buildings.find(c => c.id === this.buildingService.GetBuildingId())
            .floors.find(c => c.id === this.floorService.GetFloorId())
            .drawings.find(c => c.id === this.drawingService.GetDrawingId())
            .pins.find(c => c.id === this.SetPinId(id));
    }

    modifyPinData(type, data) {
        const siteId = this.siteService.GetSiteId();
        const siteIndex = this.siteService.siteData.findIndex(c => c.id === siteId);
        const buildingIndex = this.siteService.siteData.find(c => c.id === siteId)
            .buildings.findIndex(c => c.id === this.buildingService.GetBuildingId());

        const floorIndex = this.siteService.siteData.find(c => c.id === siteId)
            .buildings.find(c => c.id === this.buildingService.GetBuildingId())
            .floors.findIndex(c => c.id === this.floorService.GetFloorId());

        const drawingIndex = this.siteService.siteData.find(c => c.id === siteId)
            .buildings.find(c => c.id === this.buildingService.GetBuildingId())
            .floors.find(c => c.id === this.floorService.GetFloorId())
            .drawings.findIndex(c => c.id === this.drawingService.GetDrawingId());

        if (type === 'edit') {
            const pinIndex = this.siteService.siteData.find(c => c.id === siteId)
                .buildings.find(c => c.id === this.buildingService.GetBuildingId())
                .floors.find(c => c.id === this.floorService.GetFloorId())
                .drawings.find(c => c.id === this.drawingService.GetDrawingId())
                .pins.findIndex(c => c.id === data.id);

            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingIndex].pins[pinIndex].latitude = data.latitude;
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingIndex].pins[pinIndex].longitude = data.longitude;
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingIndex].pins[pinIndex].drawing_id = data.drawing_id;
        } else {
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingIndex].pins.push(data);
        }
        this.siteService.SetSitesData(this.siteService.siteData);
        return this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingIndex].pins;
    }

    SetPinId(val) {
        localStorage.setItem('pinId', JSON.stringify(val));
        this.pinId = val;
        return this.pinId;
    }

    GetPinId() {
        const pinId = localStorage.getItem('pinId');

        if (pinId) {
            try {
                this.pinId = JSON.parse(pinId);
            } catch (e) {
                localStorage.removeItem('pinId');
            }

            return this.pinId;
        }
    }

    ErrorHandler(response) {
        console.log(response);
    }
}
