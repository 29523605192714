import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS}                       from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule}                             from '@angular/forms';
import {MAT_DATE_LOCALE}                        from '@angular/material/core';
import {MatListModule}                          from '@angular/material/list';
import {MatSidenavModule}                       from '@angular/material/sidenav';
import {MatSnackBarModule}                      from '@angular/material/snack-bar';
import {BrowserModule}                          from '@angular/platform-browser';
import {BrowserAnimationsModule}                from '@angular/platform-browser/animations';
import {Router} from '@angular/router';
import {JwtModule}                              from '@auth0/angular-jwt';
import * as Sentry                              from '@sentry/angular';
import {ProductsModule}                         from '@src/app/admin/products/products.module';
import {AppRoutingModule}                       from '@src/app/app-routing.module';
import {AppComponent}                           from '@src/app/app.component';
import {CoreModule}                             from '@src/app/core/core.module';
import {TokenInterceptor}                       from '@src/app/core/interceptors/token-interceptor';
import {AdminModule}                            from '@src/app/layout/admin/admin.module';
import {environment}                            from '@src/environments/environment';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        AdminModule,
        FormsModule,
        JwtModule.forRoot({
            config: {
                throwNoTokenError: false,
                tokenGetter: () => {
                    return localStorage.getItem(`${environment.storagePrefix}_accessToken`);
                },
                allowedDomains: [
                    'localhost',
                    'localhost:4200',
                    'vulcan-api.localhost',
                    'staging.api.vulcan.hickwood.uk',
                    'test.api.vulcan.hickwood.uk',
                    'api.firesurveypro.co.uk'
                ],
                disallowedRoutes: [
                    '//vulcan-api.localhost/api/v1/login',
                    '//vulcan-api.localhost/api/v1/join/',
                ],
            },
        }),
        MatSidenavModule,
        MatListModule,
        MatSnackBarModule,
        ProductsModule
    ],
    declarations: [AppComponent],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule
{
}
