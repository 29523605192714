<h2 mat-dialog-title>{{title}}</h2>

<div mat-dialog-content>
    <ng-container *ngIf="data.action == 'replace' && newProd == false; else productFormId">
        <p style="text-align: center;">Select from existing product list</p>

        <form [formGroup]="productReplacementForm" class="form row">
                <div class="col-12 col-md-6">
                    <mat-form-field>
                        <mat-label>Products</mat-label>
                        <mat-select formControlName="product_id" [value]="1" required>
                            <mat-option *ngFor="let product of products" [value]="product.id">{{ product.name |
                                titlecase }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="text-danger"
                        *ngIf="productReplacementForm.controls.product_id.errors && (productReplacementForm.controls.product_id.dirty || productReplacementForm.controls.product_id.touched) ">
                        <div *ngIf="productReplacementForm.controls.product_id.errors.required" padding>
                            *Please select a Product.
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <mat-form-field>
                        <mat-label>Quantity</mat-label>
                        <input matInput type="number" formControlName="quantity" required />
                    </mat-form-field>
                    <div class="text-danger"
                        *ngIf="productReplacementForm.controls.quantity.errors && (productReplacementForm.controls.quantity.dirty || productReplacementForm.controls.quantity.touched) ">
                        <div *ngIf="productReplacementForm.controls.quantity.errors.required" padding>
                            *Please enter a Quantity.
                        </div>
                    </div>
                </div>
            </form>

        <div class="separator">OR</div>

        <div style="text-align: center; margin-top: 10px;">
            <button mat-stroked-button color="secondary" (click)="newProd = true;">Replace with new product</button>
        </div>
    </ng-container>

    <ng-template #productFormId>
        <form [formGroup]="productForm" class="form row">
            <div class="col-12 col-md-6">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput type="text" formControlName="name" required />
                </mat-form-field>
                <div class="text-danger"
                    *ngIf="productForm.controls.name.errors && (productForm.controls.name.dirty || productForm.controls.name.touched) ">
                    <div *ngIf="productForm.controls.name.errors.required" padding>
                        *Please enter a Name.
                    </div>
                </div>

                <mat-form-field>
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status_id" [value]="1" required>
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let status of productStatuses" [value]="status.id">{{ status.value |
                            titlecase }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="text-danger"
                    *ngIf="productForm.controls.status_id.errors && (productForm.controls.status_id.dirty || productForm.controls.status_id.touched) ">
                    <div *ngIf="productForm.controls.status_id.errors.required" padding>
                        *Please select a Status.
                    </div>
                </div>

                <mat-form-field>
                    <mat-label>Manufacturer</mat-label>
                    <mat-select formControlName="manufacturer_id" required>
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let manufacturer of manufacturers" [value]="manufacturer.id">{{
                            manufacturer.value | titlecase }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="text-danger"
                    *ngIf="productForm.controls.manufacturer_id.errors && (productForm.controls.manufacturer_id.dirty || productForm.controls.manufacturer_id.touched) ">
                    <div *ngIf="productForm.controls.manufacturer_id.errors.required" padding>
                        *Please select a Manufacturer.
                    </div>
                </div>

                <!-- <mat-form-field>
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="item_type_id" required>
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let type of itemTypes" [value]="type.id">{{ type.title }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="text-danger"
                    *ngIf="productForm.controls.item_type_id.errors && (productForm.controls.item_type_id.dirty || productForm.controls.item_type_id.touched) ">
                    <div *ngIf="productForm.controls.item_type_id.errors.required" padding>
                        *Please select a Type.
                    </div>
                </div> -->

                <form [formGroup]="newProductForm" *ngIf="data.action == 'replace'">
                    <mat-form-field>
                        <mat-label>Quantity</mat-label>
                        <input matInput type="number" formControlName="quantity" required />
                    </mat-form-field>
                </form>
                <div class="text-danger"
                    *ngIf="this.data.action === 'replace' && newProductForm.controls.quantity.errors && (newProductForm.controls.quantity.dirty || newProductForm.controls.quantity.touched) ">
                    <div *ngIf="newProductForm.controls.quantity.errors.required" padding>
                        *Please enter a Quantity.
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <mat-form-field>
                    <mat-label>Direct Employee Cost (£)</mat-label>
                    <input matInput type="text" formControlName="direct_employee_cost" required />
                </mat-form-field>
                <div class="text-danger"
                    *ngIf="productForm.controls.direct_employee_cost.errors && (productForm.controls.direct_employee_cost.dirty || productForm.controls.direct_employee_cost.touched) ">
                    <div *ngIf="productForm.controls.direct_employee_cost.errors.required" padding>
                        *Please enter a Direct Employee Cost.
                    </div>
                </div>

                <mat-form-field>
                    <mat-label>Sub Contractor Cost (£)</mat-label>
                    <input matInput type="text" formControlName="sub_contractor_cost" required />
                </mat-form-field>
                <div class="text-danger"
                    *ngIf="productForm.controls.sub_contractor_cost.errors && (productForm.controls.sub_contractor_cost.dirty || productForm.controls.sub_contractor_cost.touched) ">
                    <div *ngIf="productForm.controls.sub_contractor_cost.errors.required" padding>
                        *Please enter a Sub Contractor Cost.
                    </div>
                </div>

                <mat-form-field>
                    <mat-label>Material Cost (£)</mat-label>
                    <input matInput type="text" formControlName="material_cost" required />
                </mat-form-field>
                <div class="text-danger"
                    *ngIf="productForm.controls.material_cost.errors && (productForm.controls.material_cost.dirty || productForm.controls.material_cost.touched) ">
                    <div *ngIf="productForm.controls.material_cost.errors.required" padding>
                        *Please enter a Material Cost.
                    </div>
                </div>

                <mat-form-field>
                    <mat-label>Sell (£)</mat-label>
                    <input matInput type="text" formControlName="sell_price" required />
                </mat-form-field>
                <div class="text-danger"
                    *ngIf="productForm.controls.sell_price.errors && (productForm.controls.sell_price.dirty || productForm.controls.sell_price.touched) ">
                    <div *ngIf="productForm.controls.sell_price.errors.required" padding>
                        *Please enter a Sell Price.
                    </div>
                </div>

                <mat-slide-toggle formControlName="diameter_check">
                    Is product measured by Diameter?</mat-slide-toggle>

                <div *ngIf="productForm.controls.diameter_check.value == false; else diameter">
                    <mat-form-field>
                        <mat-label>Width (mm)</mat-label>
                        <input matInput type="text" formControlName="width" />
                    </mat-form-field>
                    <div class="text-danger"
                        *ngIf="productForm.controls.width.errors && (productForm.controls.width.dirty || productForm.controls.width.touched) ">
                        <div *ngIf="productForm.controls.width.errors.required" padding>
                            *Please enter a Width.
                        </div>
                    </div>

                    <mat-form-field>
                        <mat-label>Height (mm)</mat-label>
                        <input matInput type="text" formControlName="height" />
                    </mat-form-field>
                    <div class="text-danger"
                        *ngIf="productForm.controls.height.errors && (productForm.controls.height.dirty || productForm.controls.height.touched) ">
                        <div *ngIf="productForm.controls.height.errors.required" padding>
                            *Please enter a Height.
                        </div>
                    </div>
                </div>

                <ng-template #diameter>
                    <mat-form-field>
                        <mat-label>Diameter (mm)</mat-label>
                        <input matInput type="text" formControlName="diameter" />
                    </mat-form-field>
                    <div class="text-danger"
                        *ngIf="productForm.controls.diameter.errors && (productForm.controls.diameter.dirty || productForm.controls.diameter.touched) ">
                        <div *ngIf="productForm.controls.diameter.errors.required" padding>
                            *Please enter a Diameter.
                        </div>
                    </div>
                </ng-template>
            </div>
        </form>
    </ng-template>
</div>

<div mat-dialog-actions align="end">
    <button mat-stroked-button (click)="closeDialog()">Cancel</button>
    <button mat-raised-button color="primary" (click)="submitForm()">Save</button>
</div>
