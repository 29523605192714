import {HttpClient}      from '@angular/common/http';
import {Injectable}      from '@angular/core';
import {BuildingService} from '@src/app/admin/buildings/services/building.service';
import {FloorService}    from '@src/app/admin/floors/services/floor.service';
import {SiteService}     from '@src/app/admin/sites/services/site.service';
import {environment}     from '@src/environments/environment';
import {Observable}      from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DrawingService
{
    public baseUrl: string;
    public drawingId: number;

    constructor(
        private http: HttpClient,
        private siteService: SiteService,
        private buildingService: BuildingService,
        private floorService: FloorService,
    )
    {
        this.baseUrl = environment.apiUrl;
    }

    CreateDrawing(data, file: File): Observable<any>
    {
        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('floor_id', data.floor_id);
        formData.append('plan_file', file, file.name);

        if (data.start_at) {
            formData.append('start_at', data.start_at);
        }

        return this.http.post(`${this.baseUrl}/drawings`, formData).pipe();
    }

    EditDrawing(data, id): Observable<any>
    {
        return this.http.put(`${this.baseUrl}/drawings/${id}`, data).pipe();
    }

    DeleteDrawing(id): Observable<any>
    {
        return this.http.delete(`${this.baseUrl}/drawings/${id}`).pipe();
    }

    GetDrawingData(id)
    {
        this.siteService.GetSitesData();
        return this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId())
            .buildings.find(c => c.id === this.buildingService.GetBuildingId())
            .floors.find(c => c.id === this.floorService.GetFloorId())
            .drawings.find(c => c.id === this.SetDrawingId(id));
    }

    modifyDrawingData(type, data)
    {
        const siteIndex = this.siteService.siteData.findIndex(c => c.id === this.siteService.GetSiteId());
        const buildingIndex = this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId()).buildings.findIndex(c => c.id === this.buildingService.GetBuildingId());
        const floorIndex = this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId()).buildings.find(c => c.id === this.buildingService.GetBuildingId()).floors.findIndex(c => c.id === this.floorService.GetFloorId());

        if (type === 'edit') {
            const drawingsIndex = this.siteService.siteData.find(c => c.id === this.siteService.GetSiteId()).buildings.find(c => c.id === this.buildingService.GetBuildingId()).floors.find(c => c.id === this.floorService.GetFloorId()).drawings.findIndex(c => c.id === data.id);
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingsIndex].name = data.name;
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingsIndex].plan_file_path = data.plan_file_path;
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingsIndex].start_at = data.start_at;
            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings[drawingsIndex].updated_at = data.updated_at;
        } else {
            console.log(this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex]);
            if (!this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings) {
                this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings = [];
            }

            if (!data.pins) {
                data.pins = [];
            }

            this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings.push(data);
        }

        this.siteService.SetSitesData(this.siteService.siteData);
        return this.siteService.siteData[siteIndex].buildings[buildingIndex].floors[floorIndex].drawings;
    }

    SetDrawingId(id)
    {
        localStorage.setItem('drawingId', JSON.stringify(id));
        this.drawingId = id;
        return this.drawingId;
    }

    GetDrawingId()
    {
        const drawingId = localStorage.getItem('drawingId');

        if (drawingId) {
            try {
                this.drawingId = JSON.parse(drawingId);
            } catch (e) {
                localStorage.removeItem('drawingId');
            }

            return this.drawingId;
        }
    }

    GetDrawingsReport(id): Observable<any>
    {
        return this.http.get(`${this.baseUrl}/drawings/${id}/report/pins-by-status`).pipe();
    }

    ErrorHandler(response)
    {
        console.log(response);
    }
}
