<h2 mat-dialog-title class="m-0">User Preferences</h2>
<form [formGroup]="userPreferecesForm" (ngSubmit)="onSubmit()">
	<div mat-dialog-content>
		<mat-tab-group>
			<mat-tab label="Pin Size">
				<div class="center-slider">
                    <mat-slider
                        class="slider-formatting"
                        [max]="100"
                        [min]="10"
                        [step]="10"
                        [thumbLabel]="true"
                        [tickInterval]="10"
                        [displayWith]="formatLabel"
                        [value]="50"
                        color="#e87130"
                        (change)="calculatePinSize($event)"
                        aria-labelledby="Pin_Slider">
                    </mat-slider>
                </div>

                <div id="mapPref" style="min-height: 500px;"></div>
			</mat-tab>
		</mat-tab-group>
	</div>

	<div mat-dialog-actions>
        <div class="col-12">
            <button mat-stroked-button mat-dialog-close>Cancel</button>
            <button type="submit" class="float-end" mat-raised-button
                color="primary" cdkFocusInitial>Save Preferences</button>
        </div>
    </div>
</form>