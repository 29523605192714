import {HttpClient}            from '@angular/common/http';
import {Injectable}            from '@angular/core';
import {JwtHelperService}      from '@auth0/angular-jwt';
import {setUser}               from '@sentry/angular';
import {UsersService}          from '@src/app/admin/users/services/users.service';
import {PermissionsService}    from '@src/app/core/services/permissions.service';
import {BaseService}           from '@src/app/shared/services/base.service';
import {environment}           from '@src/environments/environment';
import {Observable, of}        from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {StorageService}        from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService
{
    public authenticated = false;

    constructor(
        public jwtHelper: JwtHelperService,
        private httpClient: HttpClient,
        private usersService: UsersService,
        private permissionsService: PermissionsService,
        private storageService: StorageService
    )
    {
        super();
    }

    get accessToken(): string
    {
        return this.storageService.read('accessToken') ?? null;
    }

    set accessToken(token: string)
    {
        this.storageService.write('accessToken', token);
    }

    get tenant(): any
    {
        return JSON.parse(this.storageService.read('tenant')) ?? null;
    }

    set tenant(tenant: any)
    {
        this.storageService.write('tenant', JSON.stringify(tenant));
    }

    public login(credentials: { email: string, password: string }): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/login`, credentials).pipe(
            switchMap((response: any) => {
                this.authenticateUser(response);
                return of(response);
            })
        );
    }

    public forgotPassword(email: string): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/forgot-password`, email).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public createPassword(url: string, passwordData): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${url}`, passwordData).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public resetPassword(credentials: { token: string, password: string, password_confirmation: string }): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/reset-password`, credentials).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public verifyEmail(url: string): Observable<any>
    {
        return this.httpClient.get(url).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public resendVerificationEmail(email: string): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/email/verify/${email}/resend`, {frontend_url: `${environment.localPath}/email-verified`}).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public signInUsingToken(): Observable<any>
    {
        console.log('%c Sign in via token', 'color: orange;');

        if (this.accessToken) {
            return this.httpClient.post(`${this.apiUrl}/refresh`, {
                token: this.accessToken,
                tenant_id: this.tenant.id
            }).pipe(
                catchError(() => of(false)),
                switchMap((response: any) => {
                    this.authenticateUser(response);
                    return of(true);
                })
            );
        }

        return of(false);
    }

    public logout(): Observable<any>
    {
        this.deauthenticateUser();
        return of(true);
    }

    public check(): Observable<boolean>
    {
        if (this.authenticated) {
            return of(true);
        }

        if (!this.accessToken) {
            return of(false);
        }

        if (this.jwtHelper.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        return this.signInUsingToken();
    }

    public checkTenant(): Observable<boolean>
    {
        console.log('checking tenant', this.tenant);

        if (this.tenant?.billing_address && this.tenant.trading_address) {
            return of(true);
        } else {
            return of(false);
        }
    }

    public register(registerForm): Observable<any>
    {
        return this.httpClient.post(`${this.apiUrl}/register`, registerForm).pipe();
    }

    private authenticateUser(response: any): void
    {
        console.log('Auth Logged In', response);
        this.authenticated = true;
        this.usersService.user = response.data.user;
        this.tenant = response.data.primary_tenant;
        this.accessToken = response.data.token;
        this.permissionsService.roles = response.data.user.roles;
        this.permissionsService.permissions = response.data.user.permissions;

        setUser({
            id: this.usersService.user.id,
            username: this.usersService.user.name,
            email: this.usersService.user.email
        });
    }

    private deauthenticateUser()
    {
        this.authenticated = false;
        this.usersService.user = null;

        this.storageService.remove('accessToken');
        this.storageService.remove('tenant');
        this.storageService.remove('roles');
        this.storageService.remove('permissions');

        setUser(null);
    }
}
